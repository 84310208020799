import React from 'react';
import { Tree } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import "./LeftTreeView.css";

function Demo() {
    return (
        <Tree
          className ="Tree"
          switcherIcon={<DownOutlined className="Icon"/>}
          defaultExpandedKeys={['0-0-0']}
          showLine={{showLeafIcon:false}}
          draggable
          treeData={[
            {
              title: 'parent 1',
              key: '0-0',
              children: [
                {
                  title: 'parent 1-0',
                  key: '0-0-0',
                  children: [
                    {
                      title: 'lmdsb',
                      key: '0-0-0-0',
                      children: [
                        {
                          title: 'leafffffffffffffffffffffffffffffffffffffffffffffff',
                          key: '0-0-0-0-0',
                          children : [
                              {
                                  title: "leaf",
                                  key: '0-0-0-0-0-0',
                                  children : [
                                    {
                                        title: "leaf",
                                        key: '0-0-0-0-0-0-0',
                                        children : [
                                            {
                                                title: "leaf",
                                                key: '0-0-0-0-0-0-0-0',
                                                children : [
                                                    {
                                                        title: "leaf",
                                                        key: '0-0-0-0-0-0-0-0-0',
                                                        children : [
                                                            {
                                                                title: "leaf",
                                                                key: '0-0-0-0-0-0-0-0-0-0',
                                                                children : [
                                                                    {
                                                                        title: "leaf",
                                                                        key: '0-0-0-0-0-0-0-0-0-0-0',
                                                                    }
                                                                ]
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                ]
                              }
                          ]
                        },
                        {
                          title: 'leaf',
                          key: '0-0-0-0-1',
                        },
                        {
                          title: 'leaf',
                          key: '0-0-0-0-2',
                        },
                      ],
                    },
                    {
                      title: 'leaf',
                      key: '0-0-0-1',
                    },
                    {
                      title: 'leaf',
                      key: '0-0-0-2',
                    },
                  ],
                },
                {
                  title: 'parent 1-1',
                  key: '0-0-1',
                  children: [
                    {
                      title: 'leaf',
                      key: '0-0-1-0',
                    },
                  ],
                },
                {
                  title: 'parent 1-2',
                  key: '0-0-2',
                  children: [
                    {
                      title: 'leaf',
                      key: '0-0-2-0',
                    },
                    {
                      title: 'leaf',
                      key: '0-0-2-1',
                    },
                  ],
                },
              ],
            },
          ]}
        />
      );
}

export default Demo; 