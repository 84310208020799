import React from 'react';
import { Button } from 'antd';
import {
  DownOutlined,
} from '@ant-design/icons';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import {signInFromFb} from '../../SignInFromFb.tsx';
/* replace-start-value = import { isImg } from './utils'; */

/* replace-end-value */
/* replace-start */
import './Banner.scss';
/* replace-end */
class Banner extends React.PureComponent {
  render() {
    const { ...currentProps } = this.props;
    delete currentProps.dataSource;
    delete currentProps.isMobile;
    return (
      <div
        className='banner0'
        {...currentProps}
      >
        <QueueAnim
          key="QueueAnim"
          type={['bottom', 'top']}
          delay={200}
          className= 'banner0-text-wrapper'
        >
          <div
            key="title"
            className='banner0-title'
            children='https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png'
            /* replace-start */
            data-edit="text,image"
            /* replace-end */
          >
            NettyMappy
          </div>
          <div
            key="content"
            className='banner0-content'
            children='一个高效的页面动画解决方案'
          >
            {/* replace-start-value = dataSource.content.children */
            <span>what the fuck</span>
           /* replace-end-value */}
          </div>
          <Button
            ghost
            key="button"
            className='banner0-button'
            children='Learn More'
            /* replace-start */
            data-edit="link,text"
            onClick={signInFromFb}
            /* replace-end */
          >
            {/* replace-start-value = dataSource.button.children */
            <span>Sign in from Facebook</span>
           /* replace-end-value */}
          </Button>
        </QueueAnim>
        <TweenOne
          animation={{
            y: '-=20', yoyo: true, repeat: -1, duration: 1000,
          }}
          className="banner0-icon"
          key="icon"
        >
          <DownOutlined />
        </TweenOne>
      </div>
    );
  }
}
export default Banner;
