import './Header.css';

type HeaderProps = {
    currentWorkspaceLocation:string
}
function Header(props:HeaderProps) {
  const headerText:string = "NettyMappy - " + props.currentWorkspaceLocation; 
  return (
    <div className="Header">
      {headerText}
    </div>
  );
}

export default Header;  