import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Landing from './landingPage/Landing'
import Workspace from './workspace/Workspace'
import reportWebVitals from './reportWebVitals';

FB.getLoginStatus(function(response) {
  console.log(response);
  if (response.status === 'connected') {
    ReactDOM.render(
      <React.StrictMode>
        <Workspace />
      </React.StrictMode>,
      document.getElementById('root')
    );
  } else {
    ReactDOM.render(
      <React.StrictMode>
        <Landing/>
      </React.StrictMode>,
      document.getElementById('root')
    );
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
