import './BodyWithSplitPane.css';
import SplitPane from "react-split-pane";
import Demo from "./LeftTreeView";

function BodyWithSplitPane() {
    return (
        <SplitPane
            className="body"
            split="vertical"
            minSize={250}
        >
            <div id="left"> <Demo /> </div>
            <div > </div>
        </SplitPane>
    );
}

export default BodyWithSplitPane; 