import Header from './Header'
import BodyWithSplitPane from './BodyWithSplitPane';

function Workspace() {
    return <div> 
            <Header currentWorkspaceLocation="Mengda"/> 
            <BodyWithSplitPane />
        </div>;
}

export default Workspace;