import React from 'react';
import ReactDOM from 'react-dom';
import Workspace from './workspace/Workspace'

export function signInFromFb(): void {
    FB.login((response) => {
        console.log(response);
        ReactDOM.render(
            <React.StrictMode>
              <Workspace />
            </React.StrictMode>,
            document.getElementById('root')
          );
    })
}



